import React, { useEffect , useCallback , useState } from 'react';
import { useParams } from "react-router-dom";

import { useGlobalContext } from '../../context/GlobalContext';

import { useForm } from "react-hook-form";
import { API_URL } from '../../../actions';
import SelectMedia from '../medias/SelectMedia'; 
import PromptRouter from '../../partials/PromptRouter';
import RemoveModale from '../../partials/RemoveModale/RemoveModale';

import { getMediasAction } from '../../../actions/mediasActions';
import { getCompanyAction } from '../../../actions/companiesActions';
import { getPageAction , updatePageAction } from '../../../actions/themeActions';

import Loader from '../../partials/Loader';
import TextEditor from '../../partials/TextEditor';

import '../../../styles/pages-sites.scss';
import Training from './Training/Training';
import Contact from './Contact/Contact';
// import Picture from '../medias/Picture';


export default function School({ setDisplayNavBar, setDisplaySelectLang , setDisplaySiteNav ,  setActivePage , theme , lang }) {

  const param = useParams();

  const page = 'school';

  const { register, errors } = useForm();

  const [ context, dispatch ] = useGlobalContext();

  const [ form , setForm ] = useState();

  const [ isMounted , setIsMounted ] = useState(false);
  const [ isSaved , setIsSaved ] = useState(true);
  const [ removeAction , setRemoveAction ] = useState();

  const [ displaySelectMedia , setDisplaySelectMedia ] = useState(false);
  const [ selectedMediaList , setSelectedMediaList ] = useState([]);
  const [ mediaIsMulti , setMediaIsMulti ] = useState(false);
  const [ mediaType , setMediaType ] = useState();
  const [ mediaKey , setMediaKey ] = useState('picture');
  const [ publishIsLoading , setPublishIsLoading ] = useState(false);

  const getCompany = useCallback(() => {
    getCompanyAction(dispatch , param.companyId );
    getMediasAction(dispatch);
  },[dispatch , param.companyId ]);

  useEffect(() => {
    setDisplayNavBar(true);
    setDisplaySelectLang(true);
    setDisplaySiteNav(true)
    setActivePage(page);
    getCompany();
    return () => {
      setDisplaySelectLang(false);
      setForm();
      setIsMounted(false);
      dispatch({
        type:'RESET_PAGE',
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getPageAction(dispatch , param.companyId , page , lang );
    setForm();
    setIsMounted(false);
    dispatch({
      type:'RESET_PAGE',
    });
    // eslint-disable-next-line
  },[lang]);

  useEffect(() => {
    if (context.theme.page) {
      setForm({...context.theme.page});
      setIsSaved(true);
      setIsMounted(true);
    } else {
      setForm();
      setIsMounted(false);
    };

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });
    // eslint-disable-next-line
  }, [context.theme.page]);
  // useEffect(() => {
  //   if (form?.$init && context.theme.page) {
  //     if (form?.$init && context.theme.page && JSON.stringify(form) === JSON.stringify(context.theme.page)) {
  //       setIsSaved(true);
  //     } else {
  //       setIsSaved(false);
  //     }
  //   }
  //   // eslint-disable-next-line
  // },[form]);

  useEffect(() => {
    if (context.theme && context.theme.message === 'Theme successfully updated' ) {

      setPublishIsLoading(false);
      setIsSaved(true);

      dispatch({
        type:'MESSAGE_PAGE',
        payload: null
      });

      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La page a bien été enregistré",
          type: "success"
        }
      });

    };

    // eslint-disable-next-line
  },[context.theme.message]);

  async function handleChangeTraining(val , i) {
    var blocs = [...form.formations.blocs];
    blocs[i] = val;
    setIsSaved(false);
    const data = {...form ,
      formations: {
        ...form.formations,
        blocs,
      }
    }
    await setForm({...data});
    await submitGroup(data);
  }

  async function addTraining(value) {
    var blocs = [...form.formations.blocs, value];
    setIsSaved(false);
    await setForm({...form ,
      formations: {
        ...form.formations,
        blocs,
      }
    });
  }

  async function deleteTraining(i) {
    var blocs = [...form.formations.blocs];
    setIsSaved(false);
    await setForm({...form ,
      formations: {
        ...form.formations,
        blocs: blocs.filter((item, index) => index !== i ),
      }
    });
  }

  async function addContact(value) {
    var blocs = [...form.contact.blocs, value];
    setIsSaved(false);
    await setForm({...form ,
      contact: {
        ...form.contact,
        blocs,
      }
    });
    await submitGroup();
  }

  async function handleChangeContact(val , i) {
    var blocs = [...form.contact.blocs];
    blocs[i] = val;
    setIsSaved(false);
    await setForm({...form ,
      contact: {
        ...form.contact,
        blocs,
      }
    });
  }

  async function deleteContact(i) {
    var blocs = [...form.contact.blocs];
    setIsSaved(false);
    await setForm({...form ,
      contact: {
        ...form.contact,
        blocs: blocs.filter((item, index) => index !== i ),
      }
    });
    await submitGroup();
  }

  async function handleChangeKey( value , obj , key ) {
    let data = {...form}
    data = Object.assign({} , data );
    data[obj][key] = value;
    setIsSaved(false);
    await setForm(data);
  };
  
  async function handleChangeObj(obj) {
    let data = {...form, ...obj}
    setIsSaved(false);
    await setForm(data);
  };

  async function submitMedia(value) {
    let formData = {...form }
    formData = Object.assign({} , formData );
    const splited = mediaKey.split('.');
    console.log(splited[0], splited[2]);
    if (splited[0] === 'formations' && splited[2] === 'picture') {
      formData.formations.blocs[parseInt(splited[1])].picture = value[0];
    }
    if (splited[0] === 'formations' && splited[2] === 'file') {
      formData.formations.blocs[parseInt(splited[1])].file = value[0];
    }
    switch (mediaKey) { 
      case "certificate":
          formData.certificate.picture = value[0]
        break;

      case "text3":
          formData.text3.picture = value[0]
        break;

      case "cover":
          formData.cover = value[0]
        break;
    
      default:
        break;
    }

    await setForm({ ...formData });
    await setSelectedMediaList([]);
    await setMediaKey();
    await submitGroup();
  };

  function openSelectPicture( imgkey ) {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey(imgkey);
    setMediaType('image');
    if (form && form[imgkey] && form[imgkey].picture && form[imgkey].picture._id) {
      var pictureArray = [];
      pictureArray.push(form[imgkey].picture._id);
      setSelectedMediaList(pictureArray)
    }
  }

  function openSelectFile( imgkey ) {
    setDisplaySelectMedia(true);
    setMediaIsMulti(false);
    setMediaKey(imgkey);
    setMediaType('school');
    if (form && form[imgkey] && form[imgkey].picture && form[imgkey].picture._id) {
      var pictureArray = [];
      pictureArray.push(form[imgkey].picture._id);
      setSelectedMediaList(pictureArray)
    }
  }

  const submitGroup = async (values = {})  => {
  
    setPublishIsLoading(true);
    var groupData = {...form, ...values};
    var obj = {};
    for (const [key, value] of Object.entries(groupData)) {
      obj[`${page}.${key}`] = value;
    }

    dispatch({
      type:'ERROR_PAGE',
      payload: null
    });

    dispatch({
      type:'MESSAGE_PAGE',
      payload: null
    });

    await updatePageAction(dispatch , context.companies.company.theme[lang] , 'group' , page , obj  );

  };

  function renderImgLarge(key) {
    var foundImg = context?.medias?.mediasList?.find(item => 
        item._id === form[key]?.picture?._id
        || item._id === form[key]?._id
        || item._id === form[key]?.picture
        || item._id === form[key]
      );
    if (foundImg) {
      return <img src={API_URL + '/' + foundImg.path} alt={key} className="img-large" />
    }
  }

  function renderEdit() {
    return (
      <div className="list-item transition edit-row">
        <form autoComplete="nope">
      
            <div className="form-row">
              <div className="col-1">
                <div className="title">
                  <h2>Title de la page</h2>
                </div>

                <textarea
                  name="title"
                  onChange={(e) => handleChangeObj({title: e.target.value })}
                  defaultValue={form?.title ? form?.title  : undefined }
                  ref={register({
                    required: "Compléter le titre",
                  })}
                />
                
                <div className="title">
                </div>

                <label htmlFor="text1.title">Titre</label>
                <textarea
                  name="text1.title"
                  onChange={(e) => handleChangeKey( e.target.value , 'text1' , 'title' )}
                  defaultValue={form && form.text1 && form.text1.title ? form.text1.title  : undefined }
                  ref={register({
                    required: "Compléter le titre",
                  })}
                />
                <p className="error">{errors && errors.text1 && errors.text1.title && errors.text1.title.message}</p>

                <label>Paragraphe</label>
                <TextEditor
                  value={form && form.text1 && form.text1.text}
                  onChange={(newContent) => handleChangeKey( newContent , 'text1' , 'text' )} // preferred to use only this option to update the content for performance reasons
                />

                <div className="section"></div>

                <div className="title">
                  <h2>1<sup>er</sup> Paragraphe</h2>
                </div>

                <label htmlFor="text2.title">Titre</label>
                <input
                  name="text2.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'text2' , 'title' )}
                  defaultValue={form && form.text2 && form.text2.title ? form.text2.title  : undefined }
                />

                <label>Paragraphe</label>
                <TextEditor
                  value={form && form.text2 && form.text2.text}
                    onChange={newContent => handleChangeKey( newContent , 'text2' , 'text' )} // preferred to use only this option to update the content for performance reasons
                />

                <div className="section"></div>

                <div className="title">
                  <h2>Certificat</h2>
                </div>

                <label htmlFor="certificate.title">Titre</label>
                <input
                  name="certificate.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'certificate' , 'title' )}
                  defaultValue={form?.certificate?.title ? form.certificate.title  : '' }
                />

                <label>Paragraphe</label>
                <TextEditor
                  value={form?.certificate?.text || ''}
                  onChange={newContent => handleChangeKey( newContent , 'certificate' , 'text' )}
                />

                <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 900 x 600 px , 72 dpi</span></label>
                {renderImgLarge('certificate')}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("certificate")}
                    >
                    Parcourir
                  </button>

                  {form?.certificate?.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction({function: () => handleChangeKey( null , 'certificate' , 'picture' ) })}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <div className="section"></div>

                <div className="title">
                  <h2>Formations</h2>
                </div>

                <label htmlFor="formations.title">Titre</label>
                <input
                  name="formations.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'formations' , 'title' )}
                  defaultValue={form?.formations?.title || ''}
                />

                <label>paragraphe</label>
                <TextEditor
                  value={form && form.formations && form.formations.text}
                  onChange={newContent => handleChangeKey( newContent , 'formations' , 'text' )}
                />

                {form?.formations?.blocs && form.formations.blocs.map((value, i ) => (<Training
                  key={value?._id || value?.title + i || 'blocs' + i }
                  propTraining={value}
                  companyId={param.companyId}
                  lang={lang}
                  handleChangeTraining={(val) => handleChangeTraining(val , i)}
                  context={context}
                  setRemoveAction={setRemoveAction}
                  openSelectPicture={openSelectPicture}
                  openSelectFile={openSelectFile}
                  dispatch={dispatch}
                  submit={submitGroup}
                  deleteTraining={() => deleteTraining(i)}
                  index={i} />
                ))}

                <div className="btn-container" >
                  <button
                    className={`btn primary ${theme}-bg`}
                    style={{ marginBottom: 20}}
                    onClick={() => addTraining({
                      title: 'Nouvelle formation',
                      duration: '',
                      picture: null,
                      file: null,
                      location:'',
                      mode: '',
                      price: '',
                      ref:'',
                      subtopic:'',
                      text: '',
                      topic: '',
                    })}
                    type="button"
                    >
                    Ajouter une formation
                  </button>
                </div>

                <div className="title">
                  <h2>Les formations sur-mesure</h2>
                </div>

                <label htmlFor="text3.title">Titre</label>
                <input
                  name="text3.title"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'text3' , 'title' )}
                  defaultValue={form?.text3?.title ? form.text3.title  : '' }
                />

                <label>Paragraphe</label>
                <TextEditor
                  value={form?.text3?.text || ''}
                  onChange={newContent => handleChangeKey( newContent , 'text3' , 'text' )}
                />

                <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 900 x 600 px , 72 dpi</span></label>
                {renderImgLarge('text3')}
                <div
                  style={{ marginBottom: 20 }} 
                  className="btn-container">
                  
                  <button
                    type="button"
                    style={{ marginRight: 20 }}
                    className={`btn primary ${theme}-bg`}
                    onClick={() => openSelectPicture("text3")}
                    >
                    Parcourir
                  </button>

                  {form?.text3?.picture &&
                    <button
                      type="button" 
                      className={`btn primary ${theme}-bg`}
                      onClick={() => setRemoveAction({function: () => handleChangeKey( null , 'text3' , 'picture' ) })}
                      >
                      Supprimer
                    </button>
                  }
                </div>

                <div className="section"></div>

                <div className="title">
                  <h2>Contacts</h2>
                </div>

                <label htmlFor="contact.title">Titre</label>
                <input
                  name="contact.title"
                  style={{ marginBottom: 30 }}
                  onChange={(e) => handleChangeKey( e.target.value , 'contact' , 'title' )}
                  defaultValue={form?.contact?.title || ''}
                />
  
                {form?.contact?.blocs && form.contact.blocs.map((value, i ) => (<Contact
                  key={'contact' + i }
                  propContact={value}
                  handleChangeContact={(val) => handleChangeContact(val , i)}
                  setRemoveAction={setRemoveAction}
                  deleteContact={() => deleteContact(i)}
                  index={i} />
                ))}

                <div className="btn-container" >
                  <button
                    className={`btn primary ${theme}-bg`}
                    style={{ marginBottom: 20}}
                    onClick={() => addContact({
                      name: "Nouveau contact",
                      function: "",
                      phone: "",
                      fax: "",
                      email: "",
                      address: ""
                    })}
                    type="button"
                    >
                    Ajouter un contact
                  </button>
                </div>

              </div>

              <div className="col-2">
                <div className="btn-actions-container" >
                  {publishIsLoading ?
                    <div className="loader">
                      <Loader />
                    </div>
                    :
                    <button
                      className={`btn primary publish ${theme}-bg`}
                      type="submit"
                      onClick={() => submitGroup()}
                      >
                      Publier
                    </button>
                  }
                </div>
                <label>Photo de couverture <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 1236 x 698 px , 72 dpi</span></label>
                <div className="select-media">
                  {renderImgLarge('cover')}
                  <button className="btn primary"
                    style={{ marginRight: 20}}
                    type="button"
                    onClick={() => openSelectPicture('cover')}
                    >
                    Parcourir
                  </button>

                  {form.cover &&
                    <button className="btn primary"
                      type="button"
                      onClick={() => setRemoveAction({function: () => handleChangeObj({ cover: null }) })}
                      >
                      Supprimer
                    </button>
                  }
                </div>
            </div>
          </div>
        </form>
        <SelectMedia
          displaySelectMedia={displaySelectMedia}
          dispatch={dispatch}
          theme={theme}
          context={context}
          mediaType={mediaType}
          selectedMediaList={selectedMediaList}
          companyId={param.companyId}
          isMulti={mediaIsMulti}
          handleChange={(value) => submitMedia(value)}
          setDisplaySelectMedia={setDisplaySelectMedia}
        />
      </div>
    )
  }

  return (
    <>
    <PromptRouter when={!isSaved} />
    {isMounted && form && <div id="innovation" className={`page-container site ${theme}`}>
      <div className="page-content">

        <div className="title-row space-between">

          <div className="flex">

            <div className="title">
              <h1>Edition de la page école ({lang})</h1>
              <div className="bar primary"></div>
            </div>

          </div>

        </div>

        <div className="list-container">
          {form && renderEdit()}
        </div>

      </div>
    </div>}
    <RemoveModale removeAction={removeAction} setRemoveAction={setRemoveAction} theme={theme}/> 
    </>

  )
}
