import React, { useState , useEffect } from 'react';
import TextEditor from '../../../partials/TextEditor';

import edit from '../../../../assets/images/icons/edit.svg';
import remove from '../../../../assets/images/icons/remove.svg';
import close from '../../../../assets/images/icons/close.svg';
import save from '../../../../assets/images/icons/save.svg';

import styles from './Training.module.scss';
import { API_URL } from '../../../../actions';
import { useGlobalContext } from '../../../context/GlobalContext';

export default function Training({
  propTraining,
  setRemoveAction ,
  index,
  theme,
  addTraining,
  openSelectFile,
  openSelectPicture,
  handleChangeTraining,
  deleteTraining
}) {
  const [ context ] = useGlobalContext();
  const [ training , setTraining ] = useState(propTraining || {});
  
  const [ isEdit , setIsEdit ] = useState(addTraining ? true : false);

  async function handleChange(obj) {
    const trainingData = {
      ...training,
      ...obj,
    };
    await setTraining(trainingData);
    const keys = Object.keys(obj);
    console.log(keys, trainingData)
    if (keys.find(((k) => k === 'picture' || k === 'file'))) {
      await handleChangeTraining(trainingData , index);
    }
  }

  function saveTraining(bool) {  
    if (index >= 0) {
      handleChangeTraining(training , index);
    }
    setIsEdit(bool);
  }

  function renderImgLarge(key) {
    var foundImg = training[key] && context?.medias?.mediasList?.find(item => 
        item._id === training[key]?._id
        || item._id === training[key]
      );
    if (training[key]?.path) {
      return <img src={API_URL + '/' + training[key].path} alt={key} className="img-large" />
    }
    if (foundImg) {
      return <img src={API_URL + '/' + foundImg.path} alt={key} className="img-large" />
    }
  }

  function renderFile() {
    var foundImg = training.file && context?.medias?.mediasList?.find(item => 
        item._id === training.file?._id
        || item._id === training.file
      );
    if (foundImg) {
      return <a href={API_URL + '/' + foundImg.path}
        target='_blank'
        rel="noopener noreferrer"
        className={`${theme}-color`}
      >
        {foundImg.name}
      </a>
    }
  }

  useEffect(() => {
    if (!propTraining) {
      setIsEdit(true)
    } else {
      setTraining(propTraining)
    }
    // eslint-disable-next-line
  }, [propTraining?.picture, propTraining?.file]);

  function renderEdit() {
    return (
      <>
        <div className="btn-close-row">
          <button
            type="button"
            style={{ width: 30 }}
            onClick={() => saveTraining(true)}
            >
            <img src={save} alt="edit" />
          </button>
          <button
            type="button"
            onClick={() => saveTraining(false)}
            >
            <img src={close} alt="edit" />
          </button>
        </div>
        <label htmlFor={`Training${index}.title`} >Titre</label>
        <input
          name={`Training${index}.title`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ title: e.target.value })}
          value={training?.title ? training.title : ""}
          />

        <label>Photo d'illustration <span className={`${theme}-color required`}>*</span><br/><span className="note">minimum 900 x 600 px , 72 dpi</span></label>
        {renderImgLarge("picture")}
        <div
          style={{ marginBottom: 20 }} 
          className="btn-container">
          
          <button
            type="button"
            style={{ marginRight: 20 }}
            className={`btn primary ${theme}-bg`}
            onClick={() => openSelectPicture(`formations.${index}.picture`)}
            >
            Parcourir
          </button>

          {training?.picture &&
            <button
              type="button" 
              className={`btn primary ${theme}-bg`}
              onClick={() => setRemoveAction({function: async () => {
                await handleChange({ picture: null })
              }})}
              >
              Supprimer
            </button>
          }
        </div>

        <label>Fichier <span className={`${theme}-color required`}>*</span></label>
        <div className={styles.file} style={{ marginBottom: 20 }} >
          {renderFile()}
        </div>
  
        <div
          style={{ marginBottom: 20 }} 
          className="btn-container">
          
          <button
            type="button"
            style={{ marginRight: 20 }}
            className={`btn primary ${theme}-bg`}
            onClick={() => openSelectFile(`formations.${index}.file`)}
            >
            Parcourir
          </button>

          {training?.file &&
            <button
              type="button" 
              className={`btn primary ${theme}-bg`}
              onClick={() => setRemoveAction({function: async () => {
                await handleChange({ file: null })
              }})}
              >
              Supprimer
            </button>
          }
        </div>

        <label>Référence</label>
        <input
          name={`Référence${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ ref: e.target.value })}
          value={training?.ref ? training.ref : ""}
          />    

        <label>Texte</label>
        <TextEditor
          value={propTraining?.text || ''}
          onChange={newContent => setTraining((s) => ({ ...s ,text: newContent}))} 
        />

        <label>Durée</label>
        <input
          name={`duration${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ duration: e.target.value })}
          value={training?.duration ? training.duration : ""}
          />

        <label>Lieu</label>
        <input
          name={`location${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ location: e.target.value })}
          value={training?.location ? training.location : ""}
          />


        <label>Prochaine(s) session(s)</label>
        <input
          name={`sessions${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ sessions: e.target.value })}
          value={training?.sessions ? training.sessions : ""}
          />

        <label>Format</label>
        <input
          name={`mode${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ mode: e.target.value })}
          value={training?.mode ? training.mode : ""}
          />

        <label>Prix</label>
        <input
          name={`price${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ price: e.target.value })}
          value={training?.price ? training.price : ""}
          />

        <label>Formateur</label>
        <input
          name={`teacher${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ teacher: e.target.value })}
          value={training?.teacher ? training.teacher : ""}
          />

        <label>Thème</label>
        <input
          name={`topic${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ topic: e.target.value })}
          value={training?.topic ? training.topic : ""}
          />

        <label>Sous-thème</label>
        <input
          name={`subtopic${index}`}
          style={{ marginBottom: 10 }}
          onChange={(e) => handleChange({ subtopic: e.target.value })}
          value={training?.subtopic ? training.subtopic : ""}
          />        

      </>
    )
  }

  function renderRow() {
    return (
      <>
      <div className={styles.name}>
        <p className="bold">{training?.title}</p>
      </div>
      <div className="actions">

        <button
          type="button"
          onClick={() => setIsEdit(true)}
          >
          <img src={edit} alt="edit" />
        </button>
        {setRemoveAction &&
          <button
            type="button"
            onClick={() => setRemoveAction({ function: () => deleteTraining(index)} )}>
            <img src={remove} alt="remove"/>
          </button>
        }

      </div>
      </>
    )
  }

  return (
    <div id={`training${index}`} className={`${styles.training} ${!isEdit? styles.row : styles.edit }`}>
      {isEdit ? renderEdit() : renderRow()}
    </div>
  )
}
