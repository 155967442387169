import React , { useRef, useState } from 'react';
import JoditEditor from "jodit-react";
import 'jodit/build/jodit.min.css';
import { editorConfig } from '../../utils/utils';

export default function TextEditor({ value = "" , onChange }) {
  const [content, setContent] = useState(value);
  const timeOutRef = useRef();

  function handleContentChange(newContent) {
    setContent(newContent);
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current);
      timeOutRef.current = null
    }
    if (newContent) {
      timeOutRef.current = setTimeout(async () => {
        onChange(newContent);
      }, 1000); //
    }
  }

  return (
    <div style={{ width: '100%' , marginBottom: 30  }}>
      <JoditEditor
        value={content}
				config={editorConfig}
				onChange={(val) => handleContentChange(val)}
      />
    </div>
  )
}
